<template>
    <div>
        <el-container>
            <el-header height="80px">
                <el-row>
                    <vxe-form size="mini">
                        <vxe-form-item :span="3" title="账套" field="name" :item-render="{}" style="width:60px;">
                            <vxe-input v-model="p.remark" size="mini" placeholder="账套"></vxe-input>
                        </vxe-form-item>
                        <vxe-form-item :span="2" field="name" :item-render="{}">
                            <vxe-button size="mini" content="账套查询" @click="getaccounts"></vxe-button>
                        </vxe-form-item>
                        <vxe-form-item :span="2" title="编码" field="name" :item-render="{}">
                            <vxe-input v-model="params.details.codes" size="mini" placeholder=""></vxe-input>
                        </vxe-form-item>
                        <vxe-form-item :span="2" title="~" field="name" :item-render="{}">
                            <vxe-input v-model="params.details.codee" size="mini" placeholder=""></vxe-input>
                        </vxe-form-item>
                        <vxe-form-item :span="2" title="名称" field="name" :item-render="{}">
                            <vxe-input v-model="params.details.name" size="mini" placeholder=""></vxe-input>
                        </vxe-form-item>
                        <vxe-form-item :span="2" field="name" :item-render="{}">
                            <vxe-button size="mini" content="查询" @click="select"></vxe-button>
                        </vxe-form-item>
                        <vxe-form-item :span="2" field="name" :item-render="{}">
                            <vxe-button size="mini" content="同步" @click="syc"></vxe-button>
                        </vxe-form-item>
                    </vxe-form>
                </el-row>
               <!-- <el-row>
                    <template v-if="flag">
                        <vxe-button size="mini" content="同步" @click="syc"></vxe-button>
                    </template>
                    <template v-else>
                        <vxe-button size="mini" content="同步" @click="syc" disabled></vxe-button>
                    </template>
                </el-row>-->

            </el-header>
            <el-container>
                <div style="width:30%">
                    <vxe-table :cell-style="rowStyle" show-overflow highlight-current-row border show-header-overflow
                 highlight-hover-row size="mini" :data="dataset.accounts" height="520" width="320" 
                    @checkbox-change="selecta" @checkbox-all="selecta">
                        <vxe-table-column type="checkbox" width="60"></vxe-table-column>
                        <vxe-table-column field="acc2" title="数据库" width="200"></vxe-table-column>
                        <vxe-table-column field="accountName" title="套账" width="200"></vxe-table-column>
                    </vxe-table>
                </div>
                <div style="width:70%">
                    <vxe-table :cell-style="rowStyle" show-overflow highlight-current-row border show-header-overflow
                    highlight-hover-row size="mini" :data="dataset.rs" height="600" @checkbox-change="selectb" @checkbox-all="selectb">
                        <vxe-table-column type="checkbox" width="60"></vxe-table-column>
                        <vxe-table-column field="code" title="编码" width="200"></vxe-table-column>
                        <vxe-table-column field="name" title="名称" width="200"></vxe-table-column>
                    </vxe-table>
                    <vxe-pager :align="'left'" background :current-page.sync="params.page.current" :page-size.sync="params.page.size" :total="params.page.total" @page-change="pagechange" :page-sizes=[500,600,1000,2000,5000] :layouts="['PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']">
                    </vxe-pager>
                </div>
                <el-main>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>
<script>
    import ax from "@/js/ax.js";
    export default {
        name: "inventory",
        extends: ax.control(),
        data() {
            return {
                flag:true,
                paramssyc:{details:[],
                classes:[],},
                
                
            }
        },
        mounted() {
            this.dataset.type = "inventory";
            this.getaccounts();
        },
        methods: {
            select() {
                let _this = this
               
               
                if (_this.params.details.codes == '') {
                    _this.params.details.codes = ''
                }
                if (_this.params.details.codee == '') {
                    _this.params.details.codee = 'zzzzzzzzz'
                }
                ax.base.post("/syc1/selectInventory", _this.params).then(data => {
                    _this.dataset.rs = data.data.records;
                    _this.params.page.total = data.data.total;
                }).catch(data => {
                });
            },
            pagechange({ currentPage, pageSize }) {
                this.params.page.current = currentPage;
                this.params.page.size = pageSize;
                this.select();
            },
            syc() {
                let _this = this;
                _this.flag = false;
                ax.base.post("/syc1/sycinventory",_this.paramssyc).then(data=>{
                    _this.flag = true;
                    _this.$XModal.message({ message: data.data, status: 'success' })
                })
                .catch(data=>{
                    _this.flag = true;
                    _this.$XModal.message({ message: data.data, status: 'error' })
                })
            },
            selecta({ records }) {
                this.paramssyc.details = records;
            },
            selectb({ records }) {
                this.paramssyc.classes = records;
            },
        }
    }
</script>
<style scoped>
    .el-header,
    .el-footer {
        /* background-color: #b3c0d1; */
        color: #333;
        /* text-align: center; */
        /* line-height: 20px; */
    }

    .el-main {
        background-color: #e9eef3;
        color: #333;
        /* text-align: center; */
        line-height: 400px;
    }

    body>.el-container {
        margin-bottom: 40px;
    }

    .el-container:nth-child(7) .el-aside {
        line-height: 320px;
    }
</style>

